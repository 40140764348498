// Generated by Framer (e1877f1)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {wQley9xXs: {hover: true}, zKYB0noCF: {hover: true}};

const cycleOrder = ["zKYB0noCF", "wQley9xXs"];

const serializationHash = "framer-N4WS7"

const variantClassNames = {wQley9xXs: "framer-v-1k3e2si", zKYB0noCF: "framer-v-1gkpnwl"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Variant 1": "zKYB0noCF", "Variant 2": "wQley9xXs"}

const getProps = ({height, hover, id, width, ...props}) => { return {...props, n7UZhTSM_: hover ?? props.n7UZhTSM_ ?? "rgb(0, 144, 196)", variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "zKYB0noCF"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;hover?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, n7UZhTSM_, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "zKYB0noCF", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1gkpnwl", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"zKYB0noCF"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{backgroundColor: "rgb(2, 32, 43)", borderBottomLeftRadius: 16, borderBottomRightRadius: 16, borderTopLeftRadius: 16, borderTopRightRadius: 16, ...style}} variants={{"wQley9xXs-hover": {backgroundColor: n7UZhTSM_, borderBottomLeftRadius: 8, borderBottomRightRadius: 8, borderTopLeftRadius: 8, borderTopRightRadius: 8}, "zKYB0noCF-hover": {backgroundColor: n7UZhTSM_}, wQley9xXs: {backgroundColor: "rgb(43, 2, 26)", borderBottomLeftRadius: 72, borderBottomRightRadius: 72, borderTopLeftRadius: 72, borderTopRightRadius: 72}}} {...addPropertyOverrides({"wQley9xXs-hover": {"data-framer-name": undefined}, "zKYB0noCF-hover": {"data-framer-name": undefined}, wQley9xXs: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--framer-font-size": "34px", "--framer-text-color": "var(--extracted-r6o4lv, rgb(255, 255, 255))"}}>Button</motion.p></React.Fragment>} className={"framer-hwm0zu"} fonts={["Inter"]} layoutDependency={layoutDependency} layoutId={"CynCo39hx"} style={{"--extracted-r6o4lv": "rgb(255, 255, 255)", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-N4WS7.framer-132c0gh, .framer-N4WS7 .framer-132c0gh { display: block; }", ".framer-N4WS7.framer-1gkpnwl { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: 114px; justify-content: center; overflow: hidden; padding: 0px; position: relative; width: 227px; will-change: var(--framer-will-change-override, transform); }", ".framer-N4WS7 .framer-hwm0zu { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-N4WS7.framer-1gkpnwl { gap: 0px; } .framer-N4WS7.framer-1gkpnwl > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-N4WS7.framer-1gkpnwl > :first-child { margin-top: 0px; } .framer-N4WS7.framer-1gkpnwl > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 114
 * @framerIntrinsicWidth 227
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"wQley9xXs":{"layout":["fixed","fixed"]},"gwLkTnbvJ":{"layout":["fixed","fixed"]},"mEFGfNK1X":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"n7UZhTSM_":"hover"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const Frameretu5tOfrq: React.ComponentType<Props> = withCSS(Component, css, "framer-N4WS7") as typeof Component;
export default Frameretu5tOfrq;

Frameretu5tOfrq.displayName = "Button";

Frameretu5tOfrq.defaultProps = {height: 114, width: 227};

addPropertyControls(Frameretu5tOfrq, {variant: {options: ["zKYB0noCF", "wQley9xXs"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}, n7UZhTSM_: {defaultValue: "rgb(0, 144, 196)", title: "hover", type: ControlType.Color}} as any)

addFonts(Frameretu5tOfrq, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://app.framerstatic.com/Inter-Regular.cyrillic-ext-CFTLRB35.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://app.framerstatic.com/Inter-Regular.cyrillic-KKLZBALH.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://app.framerstatic.com/Inter-Regular.greek-ext-ULEBLIFV.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://app.framerstatic.com/Inter-Regular.greek-IRHSNFQB.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://app.framerstatic.com/Inter-Regular.latin-ext-VZDUGU3Q.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://app.framerstatic.com/Inter-Regular.latin-JLQMKCHE.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://app.framerstatic.com/Inter-Regular.vietnamese-QK7VSWXK.woff2", weight: "400"}]}], {supportsExplicitInterCodegen: true})